@import '~antd/dist/antd';

// Color
//@primary-color: red;

// Layout
@layout-header-background: #fff;
@layout-sider-background: #fff;
@layout-body-background: #fff;

.ant-layout-header {
    border-bottom: 1px solid #f0f0f0;
}

.ant-layout-sider {
    border-right: 1px solid #f0f0f0;
}
